* {
    font-family:'Montserrat', sans-serif;
    /* box-sizing: border-box; */
    margin: 0;
    padding: 0;
    color:white;
    /* overflow-x: hidden */
    /* color: white; */
  }

  ant-form-item-label {
      color: white;
  }

/* .ant-layout-sider {
    background-color: aqua;
} */