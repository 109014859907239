/* Styles for the select container */
.ant-select-selector {
    background-color: #1C1B1E !important;
    border-color: #B866F6 !important;
    border-width: 2px !important;
    color: white !important; /* This styles the input text color */
}

/* Styles for dropdown items */
/* .ant-select-item {
    color: white !important;
} */

/* Background color for selected and hovered item */
.ant-select-item-option-selected,
.ant-select-item-option-active {
    background-color: #6600cc !important;
}

/* Styles for dropdown items */
.ant-select-dropdown .ant-select-item {
    color: black !important; /*Change dropdown text color to black*/
    background-color: "grey" !important; /* Change dropdown background to grey */
}

.ant-select-selection-placeholder {
    color: #fff !important;
  }

.connectWalletButton {
    background-color: #8444bc;
    border-color: #B866F6;
}

.deployMinerButton {
    background-color: #8444bc;
    border-color: #B866F6;
    margin-right: 1000px;

}